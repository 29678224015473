import React, { RefObject, useEffect } from "react";
import { useTranslation } from "@emisgroup/application-intl";
import { PatientFields } from "./types";

const focusElement = (e, elementId: string) => {
    e.preventDefault();
    let element = document.getElementById(elementId);
    if (!element) return;
    if (element.className === "nhsuk-radios") {
        element = element.childNodes[0].childNodes[0] as HTMLElement;
    }
    if (element.scrollIntoView) element.scrollIntoView({ block: "center" });
    element.focus();
};

type PatientDetailsErrorsProps = {
    errorFieldNames: string[];
    patientFields: PatientFields;
    errorSummaryRef: RefObject<HTMLDivElement>;
};

const PatientDetailsErrors = ({ errorFieldNames, patientFields, errorSummaryRef }: PatientDetailsErrorsProps) => {
    useEffect(() => {
        if (errorSummaryRef.current?.scrollIntoView) errorSummaryRef.current.scrollIntoView({ behavior: "smooth" });
    }, []);

    const { t } = useTranslation();
    return (
        <div
            className="nhsuk-error-summary"
            aria-labelledby="error-summary-title"
            role="alert"
            tabIndex={-1}
            ref={errorSummaryRef}
            data-testid="error-summary"
        >
            <h2 className="nhsuk-error-summary__title" id="error-summary-title">
                {t("PatientDetails.ThereIsAProblem")}
            </h2>
            <div className="nhsuk-error-summary__body">
                <ul className="nhsuk-list nhsuk-error-summary__list">
                    {errorFieldNames.map(fieldName => (
                        <li key={fieldName}>
                            <a
                                data-testid={`error-summary-${fieldName}`}
                                href=""
                                onClick={e => focusElement(e, fieldName)}
                            >
                                {patientFields[fieldName].label}: {patientFields[fieldName].error}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default PatientDetailsErrors;
