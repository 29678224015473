import React from "react";
import { useTranslation } from "@emisgroup/application-intl";
import { Button, ButtonGroup } from "@emisgroup/ui-button";
import { Content, Dialog, DialogInner, Footer, Header } from "@emisgroup/ui-dialog";
import { InfoWarning } from "@emisgroup/icons-react";
import { TelemetryContext } from "../../telemetry";
import "./referral.scss";

type DiscardReferralDialogProps = {
    onDiscarded: () => void;
    onCancel: () => void;
};

function DiscardReferralDialog({ onDiscarded, onCancel }: DiscardReferralDialogProps) {
    const { t } = useTranslation();
    const message = t("Referral.DiscardDetail");
    const { trackReferralDecline } = React.useContext(TelemetryContext);

    const handleOpenChange = React.useCallback(
        (open: boolean) => {
            if (!open) onCancel();
        },
        [onCancel],
    );

    const handleDiscard = () => {
        trackReferralDecline({});
        onDiscarded();
    };

    return (
        <Dialog open={true} onOpenChange={handleOpenChange} preventBackdropClose={true}>
            <DialogInner>
                <Header>{t("Referral.DiscardReferral")}</Header>
                <Content className="discard-referral-dialog-content">
                    <div className="message-box">
                        <InfoWarning className="icon" title={message} />
                        <p className="message">{message}</p>
                    </div>
                </Content>
                <Footer>
                    <ButtonGroup>
                        <Button variant="filled" onClick={onCancel}>
                            {t("Referral.ContinueReferral")}
                        </Button>
                        <Button onClick={handleDiscard}>{t("Referral.DiscardReferral")}</Button>
                    </ButtonGroup>
                </Footer>
            </DialogInner>
        </Dialog>
    );
}

export default DiscardReferralDialog;
