import { useTranslation } from "@emisgroup/application-intl";
import * as React from "react";
import { SelectedCareProviderContext } from "../selectedCareProviderContext";
import { extractOpeningHours, getAddressLine, getMapUrl } from "./utils";
import { CareProviderRecord } from "../types";
import OpeningHours from "./openingHours";

type ResultProps = { term: string; careProvider: CareProviderRecord };
function Result({ term, careProvider, careProvider: { name, address, telephone, openingHours = [] } }: ResultProps) {
    const { t } = useTranslation();
    const { onSelect } = React.useContext(SelectedCareProviderContext);
    const addressLine = getAddressLine(address);

    const handleCareProviderSelected = e => {
        e.preventDefault();
        onSelect(careProvider);
    };

    const clearFocus = (e: any) => {
        e.currentTarget.blur();
    };

    return (
        <div className="result-item nhsuk-u-margin-bottom-0 nhsuk-u-padding-top-5">
            <div className="nhsuk-grid-row results__details">
                <div className="nhsuk-grid-column-one-half nhsuk-u-margin-bottom-5">
                    <div className="nhsuk-u-padding-top-0">
                        <button
                            data-testid="care-provider-list-item"
                            type="button"
                            className="care-provider-item nhsuk-heading-m"
                            onClick={handleCareProviderSelected}
                        >
                            {name}
                        </button>
                    </div>
                    <p className="nhsuk-list nhsuk-u-margin-bottom-2">{addressLine}</p>
                    <p className="nhsuk-list nhsuk-u-margin-bottom-2">{telephone}</p>
                    <div className="nhsuk-list nhsuk-u-margin-bottom-0">
                        <div className="results__item__link">
                            <a
                                href={getMapUrl(term, `${name},${addressLine}`)}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="maplink"
                                onClick={clearFocus}
                            >
                                {t("Search.MapAndDirections")}{" "}
                            </a>
                            <span className="nhsuk-u-visually-hidden">
                                `${t("Search.For").toLowerCase()} ${name}`
                            </span>
                        </div>
                    </div>
                </div>
                <div className="nhsuk-grid-column-one-half nhsuk-u-margin-bottom-5">
                    <details className="nhsuk-details nhsuk-expander">
                        <summary onClick={clearFocus} className="nhsuk-details__summary">
                            <span className="nhsuk-details__summary-text">{t("Search.OpeningTimes")}</span>
                        </summary>
                        <div className="nhsuk-details__text">
                            <OpeningHours openingHours={extractOpeningHours(openingHours)} />
                        </div>
                    </details>
                </div>
            </div>
        </div>
    );
}

export type ResultsProps = { term: string; data: { careProvider: CareProviderRecord[] } };
function Results({ term, data: { careProvider: results } }: Readonly<ResultsProps>) {
    const { t } = useTranslation();
    if (!results.length) {
        return <p data-testid="no-results">{t("Search.NoResults")}</p>;
    }
    const providersFoundTitle = `${results.length} ${
        results.length === 1 ? t("Search.ProviderFound") : t("Search.ProvidersFound")
    }`;

    return (
        <div className="nhs-grid-row">
            <div className="nhs-grid-column-full">
                <h4 className="nhsuk-heading-xs">{t("Search.NameWarning")}</h4>
                <div className="nhsuk-label">{providersFoundTitle}</div>
                <ul className="result-list">
                    {results.map(result => (
                        <li key={result.id}>
                            <Result term={term} careProvider={result} />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default Results;
