"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toError = toError;
function toError(error) {
    if (error instanceof Error)
        return error;
    try {
        return new Error(JSON.stringify(error));
    }
    catch (_a) {
        return new Error(String(error));
    }
}
