"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = exchangeToken;
const checkResponse_1 = require("./checkResponse");
function getToken(details) {
    return __awaiter(this, void 0, void 0, function* () {
        var _a;
        const formBody = [];
        for (const property in details) {
            const encodedKey = encodeURIComponent(property);
            const encodedValue = encodeURIComponent(details[property]);
            formBody.push(`${encodedKey}=${encodedValue}`);
        }
        let response;
        try {
            response = yield fetch((_a = process.env.APP_PP_TOKEN_URL) !== null && _a !== void 0 ? _a : "", {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
                },
                body: formBody.join("&"),
            });
        }
        catch (exception) {
            exception.message = `${exception.message} (get token)`;
            throw exception;
        }
        yield (0, checkResponse_1.checkResponse)(response, "Failed to retrieve token");
        const result = yield response.json();
        return result.access_token;
    });
}
function exchangeToken(token) {
    return __awaiter(this, void 0, void 0, function* () {
        return getToken({
            client_id: process.env.APP_PP_AUTH_CLIENT_ID,
            grant_type: "exchange",
            scope: process.env.APP_PP_AUTH_SCOPE,
            token,
        });
    });
}
