import * as React from "react";
import Results from "./results";
import SearchSelection from "./searchSelection";
import "../searches.scss";
import { useTranslation } from "@emisgroup/application-intl";
import { useLocation, useNavigate } from "react-router-dom";
import RootContainer from "../RootContainer/rootContainer";
import CentredContent from "../centredContent";

function Index() {
    const { t } = useTranslation();
    const [error, setError] = React.useState<string | undefined>();
    const navigate = useNavigate();
    const location = useLocation();
    const queryStringParams = new URLSearchParams(location.search);
    const name = queryStringParams.get("name");

    const handleValidSearchSelection = (searchedName: string) => {
        navigate(`/search?name=${searchedName}`);
        setError(undefined);
    };

    const handleInvalidSearch = () => {
        setError(t("Search.TooManyPractices"));
    };

    return (
        <RootContainer>
            <CentredContent>
                {error || !name ? (
                    <SearchSelection
                        onValidSearchSelection={handleValidSearchSelection}
                        initialError={error}
                        initialName={name ?? ""}
                    />
                ) : (
                    <Results name={name} onInvalid={handleInvalidSearch} />
                )}
            </CentredContent>
        </RootContainer>
    );
}

export default Index;
