import React from "react";
import { useTranslation } from "@emisgroup/application-intl";
import { EnvironmentContext } from "../../environmentContext";
import { CareProviderRecord } from "../types";
import BackLink from "../backLink";
import { PatientDetailsForm } from "../PatientDetails";
import { ReferralSubmissionContext } from "../referralSubmissionContext";
import { getAddressLine } from "../CareProviderSearch/utils";
import { ServiceContext } from "../serviceContext";

type CreateReferralProps = {
    careProvider?: CareProviderRecord;
    onBack: () => void;
};

const CreateReferral = ({ careProvider, onBack }: CreateReferralProps) => {
    const { t } = useTranslation();
    const { isPatientLed } = React.useContext(EnvironmentContext);
    const { onSubmit } = React.useContext(ReferralSubmissionContext);
    const { serviceName } = React.useContext(ServiceContext);

    return (
        <>
            <BackLink onBack={onBack} />
            <div className="nhsuk-heading-l nhsuk-u-padding-top-2">{t("PatientDetails.CreateReferral")}</div>
            <div className="nhsuk-heading-m">{t("Referral.ServiceProvider")}</div>
            <dl className="nhsuk-summary-list">
                <div className="nhsuk-summary-list__row">
                    <dt className="nhsuk-summary-list__key">{t("Referral.Service")}</dt>
                    <dd className="nhsuk-summary-list__value">{serviceName}</dd>
                </div>
                <div className="nhsuk-summary-list__row">
                    <dt className="nhsuk-summary-list__key">{t("Referral.Provider")}</dt>
                    <dd className="nhsuk-summary-list__value" data-testid="service-provider-details">
                        {careProvider.name} - {getAddressLine(careProvider.address)}
                    </dd>
                </div>
            </dl>
            <PatientDetailsForm isPatientLed={isPatientLed} onSubmit={() => onSubmit({ careProvider })} />
        </>
    );
};

export default CreateReferral;
