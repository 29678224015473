import * as React from "react";
import { ComponentContainer, AuthContext, ActionType } from "@emisgroup/clint-templates-common";
import { Runner, RunStyle } from "@emisgroup/clint-templates-runner";
import { AssessmentContext } from "../assessmentContext";
import usePatientGuid from "../usePatientGuid";
import { TelemetryContext } from "../../telemetry";
import { ServiceContext } from "../serviceContext";
import { EnvironmentContext } from "../../environmentContext";
import { HEALTHCARE_USER, PATIENT_USER } from "../../constants";

type TemplateRunnerProps = {
    templateDefinition: ComponentContainer;
    onTemplateSuccess: () => void;
    onTemplateStop: (assessmentTemplateResult: any) => void;
    onTemplateDecline: (assessmentTemplateResult: any) => void;
    onTemplateCancel: () => void;
};

const TemplateRunner = ({
    templateDefinition,
    onTemplateSuccess,
    onTemplateStop,
    onTemplateDecline,
    onTemplateCancel,
}: TemplateRunnerProps) => {
    const { getBearerToken } = React.useContext(AuthContext);
    const { isPatientLed } = React.useContext(EnvironmentContext);
    const patientGuid = isPatientLed ? "patient-led" : usePatientGuid();

    const { setAssessmentTemplateResult } = React.useContext(AssessmentContext);

    const { serviceId } = React.useContext(ServiceContext);
    const {
        trackStartAssessment,
        trackAssessmentComplete,
        trackAssessmentIneligible,
        trackAssessmentCancel,
        flushLogs,
    } = React.useContext(TelemetryContext);
    const logFlushTimerRef = React.useRef(null);
    const setLogFlushTimer = () => {
        // use a timer because track calls trigger an effect
        logFlushTimerRef.current = setTimeout(() => {
            flushLogs();
        }, 100);
    };
    React.useEffect(() => {
        return () => {
            if (logFlushTimerRef.current) {
                clearTimeout(logFlushTimerRef.current);
            }
        };
    }, []);
    const onHandleAction = (actionType: ActionType, data, validData) => {
        setAssessmentTemplateResult(data);
        switch (actionType) {
            case ActionType.CREATE:
                if (!validData) {
                    return;
                }
                trackAssessmentComplete({ patient: patientGuid, serviceId });
                onTemplateSuccess();
                break;
            case ActionType.DISCARD:
                trackAssessmentCancel({ patient: patientGuid, serviceId });
                setLogFlushTimer();
                onTemplateCancel();
                break;
            case ActionType.STOP:
                trackAssessmentIneligible({ patient: patientGuid, serviceId });
                setLogFlushTimer();
                onTemplateStop(data);
                break;
            case ActionType.DECLINE:
                // not tracked here because the user is asked for confirmation
                onTemplateDecline(data);
                break;
            default:
        }
    };

    const runnerDimensionValue = isPatientLed ? PATIENT_USER : HEALTHCARE_USER;

    React.useEffect(() => {
        trackStartAssessment({ patient: patientGuid, serviceId });
    }, []);

    return (
        <Runner
            templateDefinition={templateDefinition}
            getBearerToken={getBearerToken}
            cancelTemplate={onTemplateCancel}
            queryApiUrl=""
            ckbUrl=""
            patientId={patientGuid}
            platformUrl=""
            onHandleAction={onHandleAction}
            runStyle={RunStyle.Assessment}
            showDefaultActionButtons
            dimensionValues={[runnerDimensionValue]}
            defaultPerspectivesKey={runnerDimensionValue}
        />
    );
};

export default TemplateRunner;
