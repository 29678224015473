import * as React from "react";
import { useTranslation } from "@emisgroup/application-intl";

type PostcodeSelectionProps = {
    postcode: string;
    onChange: (postcode: string) => void;
    onSearch: () => void;
    error?: string;
    disabled?: boolean;
};

function PostcodeSelection({ postcode, onChange, onSearch, error, disabled }: Readonly<PostcodeSelectionProps>) {
    const { t } = useTranslation();

    const handleSearch = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.currentTarget.blur();
        onSearch();
    };
    const handleKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        if (ev.key === "Enter") {
            onSearch();
        }
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.currentTarget.value);

    return (
        <div className="care-provider-search nhsuk-u-padding-top-4">
            <div className={`nhsuk-form-group${error ? " nhsuk-form-group--error" : ""}`}>
                <fieldset className="nhsuk-fieldset">
                    <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--m">
                        <h1 className="nhsuk-fieldset__heading">{t("Search.SearchForProvider")}</h1>
                    </legend>
                    <label className="nhsuk-label">{t("Search.EnterPostcode")}</label>
                    <span className="nhsuk-error-message">
                        <span className="field-validation-error nhsuk-u-visually-hidden">Error:</span> {error}
                    </span>
                </fieldset>
                <input
                    className="nhsuk-input nhsuk-input--width-10"
                    data-testid="care-provider-search-box"
                    type="text"
                    disabled={disabled}
                    value={postcode}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                />
            </div>
            <div className="nhsuk-grid-row nhsuk-u-margin-top-4">
                <div className="nhsuk-grid-column-full">
                    <button
                        data-testid="search-button"
                        className={`nhsuk-button${disabled ? " nhsuk-button--disabled" : ""}`}
                        disabled={disabled}
                        type="button"
                        onClick={handleSearch}
                    >
                        {t("Search.Search")}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default PostcodeSelection;
