import React from "react";

interface TextInputFieldProps {
    id: string;
    label: string;
    error: string;
    value: string;
    onChange?: (value: string) => void;
    onBlur?: () => void;
}

const TextInputField = ({ id, label, error, value, onChange, onBlur }: TextInputFieldProps) => {
    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        onChange(e.target.value);
    }
    return (
        <div className={`nhsuk-form-group${error ? " nhsuk-form-group--error" : ""}`}>
            <label className="nhsuk-label" htmlFor={id}>
                {label}
            </label>

            {error && (
                <span className="nhsuk-error-message">
                    <span className="field-validation-error nhsuk-u-visually-hidden" id={`${id}-error`}>
                        Error:
                    </span>
                    {error}
                </span>
            )}

            <input
                id={id}
                data-testid={id}
                className="nhsuk-input"
                name={id}
                type="text"
                value={value}
                onChange={onChange ? handleChange : undefined}
                onBlur={onBlur ? onBlur : undefined}
            />
        </div>
    );
};

export default TextInputField;
