import { ActionData, ActionType, ComponentContainer } from "@emisgroup/clint-templates-common";
import { Runner, RunStyle } from "@emisgroup/clint-templates-runner";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { HEALTHCARE_USER } from "../../constants";
import { ApiContext } from "../../context/api";
import embeddedErrorTemplate from "./embeddedErrorTemplate";

const ACTION_EVENT = "action";
const ERROR_MSG = "error";
const TEMPLATE_NOT_FOUND_ERROR = "template_not_found";
const UNKNOWN_ERROR = "unknown";

function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

function postMessage(message) {
    const appHostOrigins = process.env.APP_HOST_ORIGINS?.split(",").filter(s => s) ?? [];
    appHostOrigins.forEach(origin => {
        window.parent?.postMessage(message, origin);
    });
}

function EmbeddedTemplateRunner() {
    const query = useQuery();
    const serviceId = query.get("serviceId");
    const user = query.get("user") ?? HEALTHCARE_USER;
    const [loading, setLoading] = useState(true);
    const [template, setTemplate] = useState<ComponentContainer | null>(null);
    const { getServiceTemplate } = useContext(ApiContext);

    useEffect(() => {
        const getTemplate = async () => {
            let templateValue;
            try {
                templateValue = await getServiceTemplate(serviceId);
                if (!templateValue) {
                    postMessage({ event: ERROR_MSG, type: TEMPLATE_NOT_FOUND_ERROR });
                }
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
            } catch (error) {
                postMessage({ event: ERROR_MSG, type: UNKNOWN_ERROR });
            }
            if (templateValue) {
                setTemplate(templateValue);
            } else {
                setTemplate(embeddedErrorTemplate);
            }
            setLoading(false);
        };
        getTemplate();
    }, []);

    const handleTemplateAction = (type: ActionType, data: ActionData, validData: boolean | null) => {
        if (type === ActionType.NEXTSTEP) return;
        if (type === ActionType.CREATE && !validData) return;
        postMessage({ event: ACTION_EVENT, type, data });
    };
    const handleTemplateCancelled = () => postMessage({ event: ACTION_EVENT, type: "cancel", data: {} });
    const handleTemplateError = () => {
        postMessage({ event: ERROR_MSG, type: UNKNOWN_ERROR });
        return { retryButton: false };
    };

    const render = () => {
        if (loading) {
            return <div>Loading...</div>;
        }

        return (
            <Runner
                templateDefinition={template}
                queryApiUrl=""
                ckbUrl=""
                platformUrl=""
                getBearerToken={() => Promise.resolve("")}
                cancelTemplate={handleTemplateCancelled}
                patientId="unknown"
                onHandleError={handleTemplateError}
                onHandleAction={handleTemplateAction}
                runStyle={RunStyle.Assessment}
                dimensionValues={[user]}
                defaultPerspectivesKey={user}
            />
        );
    };

    return render();
}

export default EmbeddedTemplateRunner;
