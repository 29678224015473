import { Button } from "@emisgroup/ui-button";
import * as React from "react";
import { useTranslation } from "@emisgroup/application-intl";
import DeclineReferralDialog from "./declineReferralDialog";
import { EnvironmentContext } from "../../environmentContext";

const DeclineReferralText = "Referral.DeclineReferral";

function DeclineReferral() {
    const { t } = useTranslation();
    const [showDeclineDialog, setShowDeclineDialog] = React.useState<boolean>(false);
    const { isPatientLed } = React.useContext(EnvironmentContext);

    const onCancel = () => setShowDeclineDialog(false);

    if (isPatientLed) {
        return null;
    }

    return (
        <>
            <Button
                data-testid="decline-referral-button"
                borderless={true}
                title={t(DeclineReferralText)}
                ariaLabel={t(DeclineReferralText)}
                onClick={() => setShowDeclineDialog(true)}
            >
                {t(DeclineReferralText)}
            </Button>
            {showDeclineDialog && <DeclineReferralDialog onCancel={onCancel} />}
        </>
    );
}

export default DeclineReferral;
