"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.augmentData = augmentData;
function augmentData(item, version) {
    if (!item.data) {
        return false;
    }
    item.data.version = version;
    return true;
}
