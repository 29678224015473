import * as React from "react";
import { usePacExtension } from "@emisgroup/patient-connect-common-react";
import CareProviderSearch from "../CareProviderSearch";
import { SelectedCareProviderContext } from "../selectedCareProviderContext";
import { TelemetryContext } from "../../telemetry";
import usePatientGuid from "../usePatientGuid";
import { ServiceContext } from "../serviceContext";
import { EnvironmentContext } from "../../environmentContext";
import CreateReferral from "./createReferral";
import "./Style.scss";

type PatientReferralProps = { onBack: () => void };
export const PatientReferral = ({ onBack }: PatientReferralProps) => {
    usePacExtension();
    const { isRunningInApplicationFrame } = React.useContext(EnvironmentContext);
    const { trackStartReferral } = React.useContext(TelemetryContext);
    const { serviceId } = React.useContext(ServiceContext);
    const { selectedCareProvider, onSelect } = React.useContext(SelectedCareProviderContext);
    const patientGuid = usePatientGuid();
    React.useEffect(() => {
        trackStartReferral({ patient: patientGuid, serviceId });
    }, []);

    const pageClass = isRunningInApplicationFrame ? "app-frame" : "standalone";

    const handleCreateReferralOnBack = () => {
        onSelect(null);
    };

    return (
        <div className={pageClass}>
            {!selectedCareProvider ? (
                <CareProviderSearch onBack={onBack} />
            ) : (
                <CreateReferral careProvider={selectedCareProvider} onBack={handleCreateReferralOnBack} />
            )}
        </div>
    );
};

export default PatientReferral;
