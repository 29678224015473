import React, { useState } from "react";
import { useTranslation } from "@emisgroup/application-intl";
import { ServiceContext } from "../serviceContext";
import "./practiceConfirmation.scss";

type PracticeConfirmationProps = {
    onContinue: (confirmed: boolean) => void;
};

const PracticeConfirmation = ({ onContinue }: PracticeConfirmationProps) => {
    const { t } = useTranslation();
    const { organisation } = React.useContext(ServiceContext);
    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const [continueAttempted, setContinueAttempted] = React.useState(false);
    const options = [
        { text: t("Yes"), value: "yes" },
        { text: t("No"), value: "no" },
    ];

    const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(e.currentTarget.value);
    };

    const handleContinue = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.currentTarget.blur();
        setContinueAttempted(true);
        if (selectedOption !== null) {
            onContinue(selectedOption === "yes");
        }
    };

    const error = continueAttempted && selectedOption === null ? t("Practice.SelectionMandatory") : "";
    const confirmationText = `${t("Practice.AreYouRegisteredWith")} ${organisation.name}?`;
    return (
        <div data-testid="patient-practice-confirmation" className="nhsuk-u-padding-top-7">
            <div className={`nhsuk-form-group${error ? " nhsuk-form-group--error" : ""}`}>
                <fieldset className="nhsuk-fieldset">
                    <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--m">
                        <h1 className="nhsuk-fieldset__heading">{confirmationText}</h1>
                    </legend>

                    <div className="nhsuk-radios">
                        {options.map(o => (
                            <div key={o.value} className="nhsuk-radios__item">
                                <input
                                    data-testid={`confirmation-options-${o.value}`}
                                    className="nhsuk-radios__input"
                                    id={`practice-confirmation-option-${o.value}`}
                                    type="radio"
                                    value={o.value}
                                    checked={selectedOption === o.value}
                                    onChange={handleOptionChange}
                                />
                                <label
                                    className="nhsuk-label nhsuk-radios__label"
                                    htmlFor={`practice-confirmation-option-${o.value}`}
                                >
                                    {o.text}
                                </label>
                            </div>
                        ))}
                    </div>

                    <span className="nhsuk-error-message">
                        <span className="nhsuk-u-visually-hidden">Error:</span> {error}
                    </span>
                </fieldset>
            </div>

            <div className="nhsuk-grid-row nhsuk-u-margin-top-9">
                <div className="nhsuk-grid-column-full">
                    <button
                        data-testid="continue-confirmation-button"
                        className="nhsuk-button"
                        type="button"
                        onClick={handleContinue}
                    >
                        {t("Continue")}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PracticeConfirmation;
