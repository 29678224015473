import React, { useEffect } from "react";
import { useTranslation } from "@emisgroup/application-intl";
import { Form, FormButtons } from "@emisgroup/ui-kit-react";
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from "react-google-recaptcha";
import GenderField from "./GenderField";
import DateOfBirthField from "./DateOfBirthField";
import NotesField from "./NotesField";
import { PatientDetails } from "../types";
import { assessmentTemplateValidator, patientLedValidators, receptionLedValidators, validateField } from "./validation";
import "./styles.scss";
import DeclineReferral from "../Referral/declineReferral";
import usePatientActivityTimeout from "../PatientTimeout/usePatientActivityTimeout";
import TextInputField from "./TextInputField";
import PatientDetailsErrors from "./PatientDetailsErrors";
import EmailAssessmentDetails from "./EmailAssessmentDetails";
import { AssessmentContext } from "../assessmentContext";
import { FieldName, PatientField, PatientFields, ValidationInput } from "./types";
import { PatientDetailsContext } from "../patientDetailsContext";

enum SubmitStage {
    AwaitingInput,
    VerifyHumanity,
    ReadyToSubmit,
}

export type PatientDetailsFormProps = {
    isPatientLed: boolean;
    onSubmit: (details: PatientDetails) => void;
};

const createPatientField = (label: string): PatientField => ({ label, error: "" });
const createPatientFields = (isPatientLed, t): PatientFields => {
    return {
        [FieldName.FirstName]: createPatientField(t("PatientDetails.Name.FirstName")),
        [FieldName.LastName]: createPatientField(t("PatientDetails.Name.LastName")),
        [FieldName.Gender]: createPatientField(t("PatientDetails.Gender")),
        [FieldName.NhsNumber]: createPatientField(
            `${t("PatientDetails.NhsNumber.Label")} (${t("PatientDetails.Optional")})`,
        ),
        [FieldName.DateOfBirth]: createPatientField(t("PatientDetails.DateOfBirth.Label")),
        [FieldName.ReferralReason]: createPatientField(t("PatientDetails.ReferralReason")),
        [FieldName.Notes]: createPatientField(t("PatientDetails.Notes")),
        [FieldName.Postcode]: createPatientField(t("PatientDetails.Postcode.Label")),
        [FieldName.AddressLine1]: createPatientField(t("PatientDetails.FirstLineOfAddress")),
        [FieldName.IsThirdPartyContact]: createPatientField(t("PatientDetails.Contact.TickBoxIfThirdParty")),
        [FieldName.ContactFirstName]: createPatientField(t("PatientDetails.Contact.FirstName")),
        [FieldName.ContactLastName]: createPatientField(t("PatientDetails.Contact.LastName")),
        [FieldName.MobilePhoneNumber]: createPatientField(t("PatientDetails.PhoneNumber.Mobile")),
        [FieldName.HomePhoneNumber]: createPatientField(t("PatientDetails.PhoneNumber.Home")),
        [FieldName.Email]: createPatientField(t("PatientDetails.Email.Label")),
        [FieldName.ReEnterEmail]: createPatientField(t("PatientDetails.Email.ReEnterLabel")),
        [FieldName.EmailAssessment]: createPatientField(
            t(`PatientDetails.ReceiveAssessmentInEmail${isPatientLed ? "Patient" : "Practice"}`),
        ),
    };
};

const PatientDetailsForm = ({ isPatientLed, onSubmit }: PatientDetailsFormProps) => {
    const { t } = useTranslation();
    const { assessmentTemplateResult } = React.useContext(AssessmentContext);
    const [patientFields, setPatientFields] = React.useState<PatientFields>(createPatientFields(isPatientLed, t));
    const { patientDetails, onChange, updatePatientDetails } = React.useContext(PatientDetailsContext);
    const errorFieldNames = React.useMemo(
        () =>
            Object.entries(patientFields)
                .filter(p => p[1].error !== "")
                .map(p => p[0]),

        [patientFields],
    );

    const [submitStage, setSubmitStage] = React.useState(SubmitStage.AwaitingInput);
    const [reEnterEmail, setReEnterEmail] = React.useState("");
    const [contactDetailsCleared, setContactDetailsCleared] = React.useState(false);
    const [showingErrorSummary, setShowingErrorSummary] = React.useState(false);
    const errorSummaryRef = React.useRef<HTMLDivElement>(null);

    const validators = React.useMemo(() => {
        const applicableValidators = isPatientLed ? [...patientLedValidators] : [...receptionLedValidators];
        if (assessmentTemplateResult) applicableValidators.push(assessmentTemplateValidator);
        return applicableValidators;
    }, [isPatientLed, assessmentTemplateValidator]);

    React.useEffect(() => {
        if (errorFieldNames.length === 0) setShowingErrorSummary(false);
    }, [errorFieldNames]);

    const validateInputValue = (name: FieldName, input: ValidationInput, isValidatingAllFields: boolean): boolean => {
        const result = validateField(
            name,
            { ...input, isShowingErrors: isValidatingAllFields || showingErrorSummary },
            validators,
        );
        const isValid = result.filter(f => f.error).length === 0;

        result.forEach(validity => {
            setPatientFields(currentPatientFields => ({
                ...currentPatientFields,
                [validity.field]: { ...patientFields[validity.field], error: validity.error ? t(validity.error) : "" },
            }));
        });

        return isValid;
    };

    const validateAllFields = () => {
        const fieldValidationResults = validators.map(validator => {
            const field = validator.field;
            return validateInputValue(
                field,
                {
                    value: patientDetails[field],
                    patientDetails,
                    patientFields,
                },
                true,
            );
        });
        return !fieldValidationResults.some(v => !v);
    };

    const getFieldName = componentFieldName => componentFieldName.split("-")[0] as FieldName;

    const handleBlur = (focussedFieldName: string) => {
        const field = getFieldName(focussedFieldName);
        const input: ValidationInput = { value: patientDetails[field], patientDetails, patientFields };
        validateInputValue(field, input, false);
    };

    const handleChange = (
        componentFieldName: string,
        _fieldValidityState: ValidityState,
        event: React.SyntheticEvent<HTMLFormElement>,
    ) => {
        if (!componentFieldName) {
            return;
        }
        const fieldName = getFieldName(componentFieldName);
        const inputElement = event.target as HTMLInputElement;
        const value = inputElement.type === "checkbox" ? inputElement.checked : inputElement.value;
        const input: ValidationInput = { value, patientDetails, patientFields };
        validateInputValue(fieldName, input, false);
        onChange(fieldName, value as any);
    };

    const recaptchaRef = React.useRef(null);

    const showErrorSummary = () => {
        if (!showingErrorSummary) {
            setShowingErrorSummary(true);
        } else if (errorSummaryRef.current?.scrollIntoView) {
            errorSummaryRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleSubmit = () => {
        const allFieldsValid = validateAllFields();

        if (allFieldsValid) {
            setSubmitStage(SubmitStage.VerifyHumanity);
        } else {
            showErrorSummary();
        }
    };

    const { stopTimeout } = usePatientActivityTimeout();
    useEffect(() => {
        const verifyHumanity = async () => {
            const recaptcha = recaptchaRef.current as ReCAPTCHA;
            if (!recaptcha && process.env.APP_RE_CAPTCHA_KEY) return;
            const humanityCheck = recaptcha ? await recaptcha.executeAsync() : undefined;
            if (!humanityCheck) console.warn("Humanity not verified so submission will be rejected");
            onChange("humanityCheck", humanityCheck);
            setSubmitStage(SubmitStage.ReadyToSubmit);
        };

        if (submitStage === SubmitStage.VerifyHumanity) verifyHumanity();
        if (submitStage === SubmitStage.ReadyToSubmit) {
            stopTimeout();
            onSubmit(patientDetails);
        }
    }, [submitStage]);

    // workaround DatePicker issues (https://github.com/emisgroup/ui-kit/issues/2421)
    useEffect(() => {
        if (patientDetails.dateOfBirth !== undefined) {
            const field = FieldName.DateOfBirth;
            const input: ValidationInput = { value: patientDetails[field], patientDetails, patientFields };
            validateInputValue(field, input, false);
        }
    }, [patientDetails.dateOfBirth]);
    const [dateOfBirthValue, setDateOfBirthValue] = React.useState<Date | undefined>(undefined);
    useEffect(() => {
        if (dateOfBirthValue !== patientDetails.dateOfBirth) {
            setDateOfBirthValue(patientDetails.dateOfBirth);
        }
    }, []);
    const handleDateOfBirthChange = (selectedDate: Date) => {
        if (selectedDate !== undefined) {
            setDateOfBirthValue(selectedDate);
            onChange(FieldName.DateOfBirth, selectedDate as any);
        }
    };

    React.useEffect(() => {
        if (contactDetailsCleared) {
            if (showingErrorSummary) {
                validateAllFields();
            } else {
                setPatientFields(currentPatientFields => ({
                    ...currentPatientFields,
                    [FieldName.MobilePhoneNumber]: { ...patientFields[FieldName.MobilePhoneNumber], error: "" },
                    [FieldName.HomePhoneNumber]: { ...patientFields[FieldName.HomePhoneNumber], error: "" },
                    [FieldName.Email]: { ...patientFields[FieldName.Email], error: "" },
                    [FieldName.ReEnterEmail]: { ...patientFields[FieldName.ReEnterEmail], error: "" },
                }));
            }
        }
    }, [contactDetailsCleared]);

    const handleIsThirdPartyContactChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!isPatientLed && e.target.checked && !contactDetailsCleared) {
            updatePatientDetails({
                ...patientDetails,
                [FieldName.MobilePhoneNumber]: "",
                [FieldName.HomePhoneNumber]: "",
                [FieldName.Email]: "",
            });

            setReEnterEmail("");
            setContactDetailsCleared(true);
        }
    };

    return (
        <>
            <div className="nhsuk-heading-m" data-testid="title">
                {t("PatientDetails.Form.Title")}
            </div>

            {showingErrorSummary && errorFieldNames.length > 0 && (
                <PatientDetailsErrors
                    errorFieldNames={errorFieldNames}
                    patientFields={patientFields}
                    errorSummaryRef={errorSummaryRef}
                />
            )}

            <Form onBlur={handleBlur} onChange={handleChange} onSubmit={handleSubmit}>
                <TextInputField
                    id={FieldName.FirstName}
                    label={patientFields.firstName.label}
                    error={patientFields.firstName.error}
                    value={patientDetails.firstName}
                />
                <TextInputField
                    id={FieldName.LastName}
                    label={patientFields.lastName.label}
                    error={patientFields.lastName.error}
                    value={patientDetails.lastName}
                />
                <GenderField id={FieldName.Gender} error={patientFields.gender.error} value={patientDetails.gender} />
                <TextInputField
                    id={FieldName.NhsNumber}
                    label={patientFields.nhsNumber.label}
                    error={patientFields.nhsNumber.error}
                    value={patientDetails.nhsNumber}
                />
                <DateOfBirthField
                    id={FieldName.DateOfBirth}
                    error={patientFields.dateOfBirth.error}
                    onChange={handleDateOfBirthChange}
                    value={dateOfBirthValue}
                />
                <TextInputField
                    id={FieldName.ReferralReason}
                    label={patientFields.referralReason.label}
                    error={patientFields.referralReason.error}
                    value={patientDetails.referralReason}
                />
                <NotesField id={FieldName.Notes} error={patientFields.notes.error} value={patientDetails.notes} />
                <TextInputField
                    id={FieldName.Postcode}
                    label={patientFields.postcode.label}
                    error={patientFields.postcode.error}
                    value={patientDetails.postcode}
                />
                <TextInputField
                    id={FieldName.AddressLine1}
                    label={patientFields.addressLine1.label}
                    error={patientFields.addressLine1.error}
                    value={patientDetails.addressLine1}
                />

                <div className="nhsuk-heading-m" data-testid="title">
                    {t("PatientDetails.Contact.ContactInformation")}
                </div>

                <div className="nhsuk-inset-text nhsuk-u-margin-top-1 nhsuk-u-margin-bottom-1">
                    <div className="nhsuk-checkboxes__item nhsuk-u-margin-bottom-3">
                        <input
                            className="nhsuk-checkboxes__input"
                            id={FieldName.IsThirdPartyContact}
                            type="checkbox"
                            onChange={handleIsThirdPartyContactChanged}
                            defaultChecked={patientDetails.isThirdPartyContact}
                        />
                        <label className="nhsuk-label nhsuk-checkboxes__label" htmlFor={FieldName.IsThirdPartyContact}>
                            {patientFields.isThirdPartyContact.label}
                        </label>
                    </div>
                    {patientDetails.isThirdPartyContact && (
                        <>
                            <TextInputField
                                id={FieldName.ContactFirstName}
                                label={patientFields.contactFirstName.label}
                                error={patientFields.contactFirstName.error}
                                value={patientDetails.contactFirstName}
                            />
                            <TextInputField
                                id={FieldName.ContactLastName}
                                label={patientFields.contactLastName.label}
                                error={patientFields.contactLastName.error}
                                value={patientDetails.contactLastName}
                            />
                        </>
                    )}

                    <TextInputField
                        id={FieldName.MobilePhoneNumber}
                        label={patientFields.mobilePhoneNumber.label}
                        error={patientFields.mobilePhoneNumber.error}
                        value={patientDetails.mobilePhoneNumber}
                    />
                    <TextInputField
                        id={FieldName.HomePhoneNumber}
                        label={patientFields.homePhoneNumber.label}
                        error={patientFields.homePhoneNumber.error}
                        value={patientDetails.homePhoneNumber}
                    />

                    <TextInputField
                        id={FieldName.Email}
                        label={patientFields.email.label}
                        error={patientFields.email.error}
                        value={patientDetails.email}
                    />

                    {isPatientLed && (
                        <TextInputField
                            id={FieldName.ReEnterEmail}
                            label={patientFields.reEnterEmail.label}
                            error={patientFields.reEnterEmail.error}
                            value={reEnterEmail}
                            onChange={setReEnterEmail}
                        />
                    )}

                    {assessmentTemplateResult && (
                        <EmailAssessmentDetails
                            id={FieldName.EmailAssessment}
                            error={patientFields.emailAssessment.error}
                            isPatientLed={isPatientLed}
                        />
                    )}
                </div>
                <FormButtons>
                    <div className="nhsuk-grid-row nhsuk-u-margin-top-8">
                        <div className="nhsuk-grid-column-full">
                            <button className="nhsuk-button" type="submit" onClick={e => e.currentTarget.blur()}>
                                {t("PatientDetails.CreateReferral")}
                            </button>
                        </div>
                    </div>
                    {process.env.APP_RE_CAPTCHA_KEY && (
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={process.env.APP_RE_CAPTCHA_KEY}
                            size="invisible"
                            badge="bottomright"
                        />
                    )}
                    <DeclineReferral />
                </FormButtons>
            </Form>
        </>
    );
};

export default PatientDetailsForm;
